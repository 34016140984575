import { differenceInCalendarDays, getUnixTime } from "date-fns";
import { addWidthParameter } from "../../utils/addWidthParameter";

import { Link } from "gatsby";
import React from "react";
import { format, getDuration } from "../../utils/dateUtils";

class ProductCard extends React.Component {
  dateFormat = "d MMMM yyy";

  getTitle(product) {
    if (!product || !product.cms || !product.cms.content || !product.cms.content.general) {
      return "";
    }

    return product.cms.content.general.title;
  }

  getStartDate(product) {
    if (!product || !product.erp || !product.erp.length || !product.erp[0].fromDate) {
      return "";
    }

    if (product.erp.length > 1) {
      return product.erp.length + " afreisdata";
    }

    return format(new Date(product.erp[0].fromDate), this.dateFormat);
  }

  getDurationTitle(product) {
    if (!product || !product.erp || !product.erp.length) {
      return "";
    }

    const firstOption = product.erp[0];
    return `${getDuration(firstOption.fromDate, firstOption.toDate)} nachten`;
  }

  getShipTitle(product) {
    if (
      !product ||
      !product.cms ||
      !product.cms.content ||
      !product.cms.content.general ||
      !product.cms.content.general.ship
    ) {
      return "";
    }

    return `Met de ${product.cms.content.general.ship.name}`;
  }

  getThemes(product, siteTheme) {
    if (!product || !product.cms || !product.cms.content || !product.cms.content.themes) {
      return "";
    }

    const themes = [
      product.cms.content.themes.theme1,
      product.cms.content.themes.theme2,
      product.cms.content.themes.theme3,
    ];
    const layoutThemes = [];

    themes.forEach((theme, index) => {
      if (theme) {
        layoutThemes.push(
          <div key={index} className="product-card__usp">
            <img src={`/${siteTheme}/svg/check.svg`} alt="check" className="product-card__usp-icon" />
            {theme.name}
          </div>
        );
      }
    });

    return layoutThemes;
  }

  getPriceTitle(product) {
    if (!product || !product.erp || !product.erp || !product.erp[0].averagePricePerPerson) {
      return "n.b.";
    }

    return `€ ${product.erp[0].averagePricePerPerson}`;
  }

  getPath(product) {
    if (!product || !product.cms || !product.cms.content || !product.cms.content.general) {
      return "/";
    }

    let path = `/products/${product.cms.content.general.path}`;

    if (
      !product.erp ||
      !product.erp.length ||
      !product.erp[0].fromDate ||
      !product.erp[0].toDate
    ) {
      return path;
    }

    const from = new Date(product.erp[0].fromDate);
    const to = new Date(product.erp[0].toDate);
    const duration = differenceInCalendarDays(to, from);
    const parsedFrom = getUnixTime(from);

    return `${path}?from=${parsedFrom}&duration=${duration}`;
  }

  render() {
    const { product, theme } = this.props;
    return (
      <section>
        <div className="product-card">
          <div className="product-card__header">
            <Link to={this.getPath(product)} className="link link--plain">
              <img
                src={`${addWidthParameter(product.cms.content.images.image1?.url, 1000)}`}
                alt={this.getTitle(product)}
                className="product-card__header-media"
              />
            </Link>
          </div>
          <h3 className="product-card__title">
            <Link to={this.getPath(product)} className="link link--plain">
              {this.getTitle(product)}
            </Link>
          </h3>
          <div className="product-card__tags">
            <div className="product-card__tag">{this.getStartDate(product)}</div>
            <div className="product-card__tag">{this.getDurationTitle(product)}</div>
            {}
          </div>

          <div className="product-card__ship">{this.getShipTitle(product)}</div>
          <div className="product-card__usps">{this.getThemes(product, theme)}</div>
          <div className="product-card__footer">
            <div className="product-card__price">
              <div className="product-card__price-from">Vanaf</div>
              <span className="product-card__price-amount">{this.getPriceTitle(product)}</span>{" "}
              <span className="product-card__price-pp">p.p.</span>
            </div>
            <div className="product-card__cta">
              <Link to={this.getPath(product)}>Meer info</Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ProductCard;
