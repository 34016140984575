import Button from "../../button";
import ProductFilterCruiseType from "./productFilterCruiseType";
import ProductFilterPeriod from "./productFilterPeriod";
import ProductFilterSailingArea from "./productFilterSailingArea";
import ProductFilterShippingCompany from "./productFilterShippingCompany";
import React from "react";

class ProductFilter extends React.Component {
  constructor(props) {
    super(props);

    this.handleFilterClick = this.handleFilterClick.bind(this);

    this.state = {
      selectedCruiseTypes: props.selectedCruiseTypes,
      selectedPeriod: props.selectedPeriod,
      selectedShippingCompanies: props.selectedShippingCompanies,
      selectedSailingAreas: props.selectedSailingAreas,
    };
  }

  // Actions

  handleSelectionChange(selection) {
    this.setState(selection);
  }

  handleFilterClick(e) {
    e.preventDefault();

    const { onSelectionChange } = this.props;
    const { selectedCruiseTypes, selectedPeriod, selectedShippingCompanies, selectedSailingAreas } = this.state;

    if (onSelectionChange) {
      onSelectionChange({
        selectedCruiseTypes,
        selectedPeriod,
        selectedShippingCompanies,
        selectedSailingAreas,
      });
    }
  }

  // Lifecycle

  componentDidUpdate(prevProps) {
    if (!prevProps.isActive && this.props.isActive) {
      // The product filter wasn't active previously. Update selection
      this.setState({
        selectedCruiseTypes: this.props.selectedCruiseTypes,
        selectedPeriod: this.props.selectedPeriod,
        selectedShippingCompanies: this.props.selectedShippingCompanies,
        selectedSailingAreas: this.props.selectedSailingAreas,
      });
    }
  }

  render() {
    const {
      isActive,
      cruiseTypes,
      periodMinDate,
      periodMaxDate,
      shippingCompanies,
      sailingAreas,
      onCloseFilterClick,
    } = this.props;
    const { selectedCruiseTypes, selectedPeriod, selectedShippingCompanies, selectedSailingAreas } = this.state;

    return (
      <>
        <div className={isActive ? "flyin flyin--active" : "flyin"}>
          <div className="flyin__backdrop" onClick={onCloseFilterClick}></div>
          <div className="flyin__frame">
            <div className="flyin__body">
              <h2 className="flyin__heading">Filters</h2>
              <div className="flyin__filters">
                <ProductFilterCruiseType
                  cruiseTypes={cruiseTypes}
                  selectedCruiseTypes={selectedCruiseTypes}
                  onSelectionChange={selectedCruiseTypes =>
                    this.handleSelectionChange({ selectedCruiseTypes: selectedCruiseTypes })
                  }
                />
                <ProductFilterPeriod
                  periodMinDate={periodMinDate}
                  periodMaxDate={periodMaxDate}
                  selectedPeriod={selectedPeriod}
                  onSelectionChange={selectedPeriod => this.handleSelectionChange({ selectedPeriod: selectedPeriod })}
                />
                <ProductFilterShippingCompany
                  shippingCompanies={shippingCompanies}
                  selectedShippingCompanies={selectedShippingCompanies}
                  onSelectionChange={selectedShippingCompanies =>
                    this.handleSelectionChange({
                      selectedShippingCompanies: selectedShippingCompanies,
                    })
                  }
                />
                <ProductFilterSailingArea
                  sailingAreas={sailingAreas}
                  selectedSailingAreas={selectedSailingAreas}
                  onSelectionChange={selectedSailingAreas =>
                    this.handleSelectionChange({ selectedSailingAreas: selectedSailingAreas })
                  }
                />
              </div>
            </div>

            <div className="flyin__actions" onClick={this.handleFilterClick}>
              <Button text="Filter toepassen" extraClass="button--primary" />
            </div>
          </div>
          <button type="button" className="button button--close" onClick={onCloseFilterClick}></button>
        </div>
      </>
    );
  }
}

export default ProductFilter;
