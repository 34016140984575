import React from "react";

export default ({ count, order, onFiltersClick, onSortClick }) => (
  <div className="product-actions">
    <div className="product-actions__filter" onClick={onFiltersClick}>
      <i className="fa fa-sliders-h" /> Meer filters
    </div>
    <div className="product-actions__results">{count} cruises gevonden</div>
    <div className="product-actions__sort" onClick={onSortClick}>
      <small>Sorteren op</small>
      <br />
      <i className={`fa fa-sort-amount-${order === "asc" ? "up" : "down"}-alt`} /> Prijs
    </div>
  </div>
);
