import { add, differenceInMonths, endOfMonth, endOfYear, isSameMonth, max, min, startOfDay } from "date-fns";
import { graphql } from "gatsby";
import { clone, concat, filter, find, isEmpty, maxBy, minBy, orderBy, uniqBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import ProductFilter from "../components/products/productFilter/productFilter";
import ProductList from "../components/products/productList";
import GlobalContext from "../contexts/global-provider";
import durations from "../data/qsm/durations";
import { environment } from "../environments/environment";
import { apiKey, catalogueId, host } from "../settings";
import Layout from "../templates/layout";
import { format, getDuration, getMonths } from "../utils/dateUtils";

const Products = ({ data }) => {
  const { searchSelection, sailingAreas, setSearchSelection, setSailingAreas } = useContext(GlobalContext);
  const dateFormat = "yyyy/MM/dd";
  const build = environment.builds[0];
  const theme = build.key;
  const websiteCms = data.websites.nodes.find(w => w.name === build.name);

  const getPeriod = (periodMinDate, periodMaxDate) => {
    const difference = differenceInMonths(periodMaxDate, periodMinDate);

    const period = [];
    for (let i = 0; i <= difference; i++) {
      period.push(add(periodMinDate, { months: i }));
    }
    return period;
  };

  const [isLoading, setIsLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterValues, setFilterValues] = useState({
    filterSelectedCruiseTypes: searchSelection.selectedCruiseTypes,
    filterSelectedPeriod: getPeriod(min(searchSelection.selectedDates), max(searchSelection.selectedDates)),
    filterSelectedShippingCompanies: searchSelection.selectedShippingCompanies,
    filterSelectedSailingAreas: searchSelection.selectedSailingAreas,
  });
  const [filterCruiseTypes, setFilterCruiseTypes] = useState([]);
  const [filterSailingAreas, setFilterSailingAreas] = useState([]);
  const [filterShippingCompanies, setFilterShippingCompanies] = useState([]);
  const [filterPeriodMax, setFilterPeriodMax] = useState(undefined);
  const [filterPeriodMin, setFilterPeriodMin] = useState(undefined);
  const [order, setOrder] = useState("asc");
  const [matchedProducts, setMatchedProducts] = useState([]);

  // Actions

  const handleFiltersClick = e => {
    setShowFilter(true);
  };

  const orderProductsByPrice = products => {
    return orderBy(products, [product => product.erp[0].averagePricePerPerson], [order]);
  };

  const handleSortClick = e => {
    const switchOrder = order === "asc" ? "desc" : "asc";
    const products = orderProductsByPrice(clone(filteredProducts), switchOrder);

    setOrder(switchOrder);
    setFilteredProducts(products);
  };

  const handleFilterSelectionChange = e => {
    setFilterValues({
      filterSelectedCruiseTypes: e.selectedCruiseTypes,
      filterSelectedPeriod: e.selectedPeriod,
      filterSelectedSailingAreas: e.selectedSailingAreas,
      filterSelectedShippingCompanies: e.selectedShippingCompanies,
    });
    setShowFilter(false);
  };

  const handleCloseFilterClick = e => {
    setShowFilter(false);
  };

  // Filters

  useEffect(() => {
    if (filterValues) {
      let products = clone(matchedProducts);

      // Cruise type
      if (filterValues.filterSelectedCruiseTypes?.length) {
        products = filter(products, product => {
          // Find product cruise type in selected cruise types
          return find(filterValues.filterSelectedCruiseTypes, {
            itemId: product.cms.content.general.cruiseType.itemId,
          });
        });
      }

      // Period
      if (filterValues.filterSelectedPeriod?.length) {
        products = filter(products, product => {
          const productFromDate = new Date(product.erp[0].fromDate);
          const productToDate = new Date(product.erp[0].toDate);

          return find(filterValues.filterSelectedPeriod, period => {
            return isSameMonth(period, productFromDate) || isSameMonth(period, productToDate);
          });
        });
      }

      // Shipping companies
      if (filterValues.filterSelectedShippingCompanies?.length) {
        products = filter(products, product => {
          // Find product shipping company in selected shipping companies
          return find(filterValues.filterSelectedShippingCompanies, selectedShippingCompanies => {
            return product.cms.content.general.shippingCompany?.itemId === selectedShippingCompanies?.itemId;
          });
        });
      }

      // Sailing areas
      if (filterValues.filterSelectedSailingAreas?.length) {
        const filterSailingAreasIds = filterValues.filterSelectedSailingAreas.map(f => f.id);

        products = products.filter(p =>
          p.cms.content.general.sailingAreas?.some(s =>
            filterSailingAreasIds.includes(s?.content.general.geographicalRegion?.tideId)
          )
        );
      }

      setFilteredProducts(products);
    }
  }, [filterValues, matchedProducts]);

  // Data

  const fetchSailingAreas = () => {
    const url = `${host}/api/web/search/geographicalregions`;
    return fetch(url, {
      method: "GET",
      headers: {
        "Api-Key": apiKey,
      },
    });
  };

  const fetchErpProducts = signal => {
    let { selectedDates } = searchSelection;

    if (selectedDates.length === 0) {
      selectedDates = getMonths();
    }

    let selectedMinDate = min(selectedDates);
    let selectedMaxDate = max(selectedDates);

    if (!selectedMinDate) {
      // If there's no selected min date, pick today
      selectedMinDate = startOfDay(new Date());
    }

    if (!selectedMaxDate) {
      // If there's no selected max date, pick last day of next year
      selectedMaxDate = endOfYear(add(new Date(), { years: 1 }));
    }
    selectedMaxDate = endOfMonth(selectedMaxDate);

    const searchRequest = {
      officeId: environment.builds[0].officeId,
      payload: {
        catalogueIds: [catalogueId],
        rooms: [
          {
            index: 0,
            pax: [
              {
                age: 25
              },
              {
                age: 25
              }
            ]
          }
        ],
        serviceType: 11,
        searchType: 1,
        fromDate: format(selectedMinDate, dateFormat),
        toDate: format(selectedMaxDate, dateFormat),
        productIds: []
      }
    };

    const url = `${host}/api/web/booking/v2/search`;
    return fetch(url, {
      method: "POST",
      headers: {
        "Api-Key": apiKey,
        "Content-Type": "application/json",
        "Language": "nl-BE"
      },
      body: JSON.stringify(searchRequest),
      signal
    });
  };

  const findChildNode = (node, itemId) => {
    if (node && node.children) {
      for (const child of node.children) {
        if (child.itemId === itemId) {
          return child;
        }

        const recursive = findChildNode(child, itemId);
        if (recursive) {
          return recursive;
        }
      }
    }
    return undefined;
  };

  const matchProducts = (erpProducts, shippingCompanyId) => {
    const products = [];

    let { selectedDurations } = searchSelection;

    if (!selectedDurations || !Array.isArray(selectedDurations) || !selectedDurations.length) {
      // No durations selected, select all
      selectedDurations = durations;
    }

    // Calculate duration range
    const selectedMinDuration = minBy(selectedDurations, "minDuration").minDuration;
    let selectedMaxDuration = maxBy(selectedDurations, "minDuration").maxDuration;
    if (selectedMaxDuration === -1) {
      selectedMaxDuration = 60;
    }

    for (const cmsProduct of data.cmsProducts.nodes) {
      if (!cmsProduct.content.general.product) continue;
      let allotments = erpProducts.filter(x => x.code === cmsProduct.content.general.product.code);

      allotments = allotments.filter(x => {
        const duration = getDuration(x.fromDate, x.toDate);
        return duration >= selectedMinDuration && duration <= selectedMaxDuration;
      });

      if (!allotments.length) continue;

      // Filter out QSM selected cruise types
      if (cmsProduct?.content?.general) {
        if (searchSelection.selectedCruiseTypes && searchSelection.selectedCruiseTypes.length) {
          if (
            !cmsProduct.content.general?.cruiseType ||
            !find(searchSelection.selectedCruiseTypes, {
              itemId: cmsProduct.content.general.cruiseType.itemId,
            })
          ) {
            continue;
          }
        }

        if (searchSelection.selectedSailingAreas && !isEmpty(searchSelection.selectedSailingAreas)) {
          const csmProductSailingAreas = cmsProduct.content.general.sailingAreas && !isEmpty(cmsProduct.content.general.sailingAreas) ? cmsProduct.content.general.sailingAreas.map(s => s.content?.general?.geographicalRegion?.tideId).filter(id => id !== null) : [];
          const selectedSailingAreasIds = searchSelection.selectedSailingAreas.map(s => s.id);
          const intersection = selectedSailingAreasIds.filter(id => csmProductSailingAreas.includes(id));
          if (isEmpty(intersection)) {
            continue;
          }
        }

        if (shippingCompanyId) {
          if (
            !cmsProduct.content.general?.shippingCompany ||
            !(Number(shippingCompanyId) === cmsProduct.content.general.shippingCompany.itemId)
          )
            continue;
        }

        allotments = orderBy(allotments, [allotment => allotment.averagePricePerPerson]);
        products.push({
          erp: allotments,
          cms: cmsProduct,
        });
      }
    }

    return products;
  };

  const getFilterCruiseTypes = products => {
    let cmsCruiseTypes = [];

    // Find all cruiseTypes
    if (products) {
      products.forEach(product => {
        if (
          product &&
          product.cms &&
          product.cms.content &&
          product.cms.content.general &&
          product.cms.content.general.cruiseType
        ) {
          cmsCruiseTypes.push(product.cms.content.general.cruiseType);
        }
      });
    }

    // Filter out duplicates
    cmsCruiseTypes = uniqBy(cmsCruiseTypes, cmsCruiseType => cmsCruiseType.itemId);

    return cmsCruiseTypes;
  };

  const getFilterSailingAreas = foundSailingAreas => {
    return searchSelection?.selectedSailingAreas?.length ? searchSelection.selectedSailingAreas : foundSailingAreas;
  };

  const getFilterShippingCompanies = products => {
    let shippingCompanies = [];

    // Find all shipping companies
    for (const product of products) {
      if (product?.cms?.content?.general?.shippingCompany) {
        shippingCompanies = concat(shippingCompanies, product.cms.content.general.shippingCompany);
      }
    }

    // Filter out duplicates
    shippingCompanies = uniqBy(shippingCompanies, shippingCompany => shippingCompany.itemId);

    return shippingCompanies;
  };

  // Utils

  // Lifecycle

  const fetchData = async (shippingCompanyId, signal) => {
    // Sailing areas
    let foundSailingAreas = clone(sailingAreas);
    if (!sailingAreas || !sailingAreas.length) {
      const sailingAreasResponse = await fetchSailingAreas();
      foundSailingAreas = await sailingAreasResponse.json();
      setSailingAreas(foundSailingAreas);
    }

    // ERP products
    const erpProductsResponse = await fetchErpProducts(signal);
    const erpProducts = await erpProductsResponse.json();

    // QSM products
    const qsmProducts = matchProducts(erpProducts, shippingCompanyId);

    setMatchedProducts(orderProductsByPrice(qsmProducts, order));
    setFilteredProducts(orderProductsByPrice(qsmProducts, order));

    // Filter input
    const filteredSailingAreas = getFilterSailingAreas(foundSailingAreas);
    setFilterCruiseTypes(getFilterCruiseTypes(qsmProducts));
    setFilterPeriodMin(min(searchSelection.selectedDates));
    setFilterPeriodMax(max(searchSelection.selectedDates));
    setFilterSailingAreas(filteredSailingAreas);
    setFilterShippingCompanies(getFilterShippingCompanies(qsmProducts));
    setFilterValues({
      filterSelectedCruiseTypes: getFilterCruiseTypes(qsmProducts),
      filterSelectedPeriod: getPeriod(min(searchSelection.selectedDates), max(searchSelection.selectedDates)),
      filterSelectedShippingCompanies: getFilterShippingCompanies(qsmProducts),
      filterSelectedSailingAreas: clone(filteredSailingAreas),
    });
  };

  // Lifecycle
  useEffect(() => {
    let shippingCompanyId = undefined;
    if (typeof window !== "undefined") {
      shippingCompanyId = new URLSearchParams(window.location.search).get("shippingCompany");
      if (searchSelection.shippingCompanyId !== shippingCompanyId) {
        setSearchSelection({ ...searchSelection, shippingCompanyId: shippingCompanyId });
      }
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      try {
        setIsLoading(true);
        setShowFilter(false);
        await fetchData(searchSelection.shippingCompanyId, signal);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    })();
    return () => {
      controller.abort();
    };
  }, [searchSelection, searchSelection.shippingCompanyId]);

  return (
    <Layout
      isTiny={true}
      showQsm={true}
      theme={theme}
      isTinted={true}
      erpSailingAreas={sailingAreas}
      cruiseTypes={data.cruiseTypes.nodes}
      navigation={websiteCms.content.navigation}
    >
      <ProductList
        products={filteredProducts}
        isLoading={isLoading}
        onFiltersClick={handleFiltersClick}
        onSortClick={handleSortClick}
        theme={theme}
        order={order}
        privacy={websiteCms.content.general.privacyPolicyPath}
        emailTemplate={websiteCms.content.general.conformationEmailTemplate}
      />
      <ProductFilter
        isActive={showFilter}
        cruiseTypes={filterCruiseTypes}
        periodMinDate={filterPeriodMin}
        periodMaxDate={filterPeriodMax}
        sailingAreas={filterSailingAreas}
        shippingCompanies={filterShippingCompanies}
        selectedCruiseTypes={filterValues.filterSelectedCruiseTypes}
        selectedPeriod={filterValues.filterSelectedPeriod}
        selectedShippingCompanies={clone(filterValues.filterSelectedShippingCompanies)}
        selectedSailingAreas={filterValues.filterSelectedSailingAreas}
        onSelectionChange={handleFilterSelectionChange}
        onCloseFilterClick={handleCloseFilterClick}
      />
    </Layout>
  );
};

export const query = graphql`
  query ProductsPageQuery {
    websites: allTideItemForWebsite {
      nodes {
        id
        name
        __typename
        content {
          navigation {
            contactNavigation {
              ...NavigationItemFragment
            }
            footerNavigation {
              ...NavigationItemFragment
            }
            headerNavigation {
              ...NavigationItemFragment
            }
          }
          general {
            privacyPolicyPath
            conformationEmailTemplate {
              tideId
            }
          }
        }
      }
    }
    cmsProducts: allTideItemForCruise {
      nodes {
        name
        content {
          general {
            product {
              code
              tideId
            }
            cruiseType {
              ... on TideItemForCruisetype {
                name
                itemId
                content {
                  general {
                    title
                  }
                }
              }
            }
            shippingCompany {
              ... on TideItemForRederij {
                name
                itemId
              }
            }
            sailingAreas {
              ... on TideItemForVaargebied {
                content {
                  general {
                    geographicalRegion {
                      tideId
                    }
                  }
                }
              }
            }
            isSelectTogether
            isThalassa
            title
            path
            ship {
              ... on TideItemForSchip {
                name
              }
              name
            }
          }
          images {
            image1 {
              url
            }
          }
          themes {
            theme1 {
              name
            }
            theme2 {
              name
            }
            theme3 {
              name
            }
          }
        }
      }
    }
    cruiseTypes: allTideItemForCruisetype {
      nodes {
        itemId
        name
      }
    }
  }
`;

export default Products;
