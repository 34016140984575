import { find, remove } from "lodash";

import ProductFilterBase from "./productFilterBase";
import React from "react";

class ProductFilterSailingArea extends React.Component {
  // Actions

  handleSailingAreaClick(sailingArea) {
    const { selectedSailingAreas, onSelectionChange } = this.props;

    if (find(selectedSailingAreas, { id: sailingArea.id })) {
      // Deselect sailingArea
      remove(selectedSailingAreas, selectedSailingArea => selectedSailingArea.id === sailingArea.id);
    } else {
      // Select sailingArea
      selectedSailingAreas.push(sailingArea);
    }

    // Update
    if (onSelectionChange) {
      onSelectionChange(selectedSailingAreas);
    }
  }

  // Utils

  isInSelectedSailingAreas(sailingArea) {
    const { selectedSailingAreas } = this.props;
    return Boolean(find(selectedSailingAreas, sailingArea));
  }

  // Lifecycle

  render() {
    const { sailingAreas } = this.props;

    return (
      <ProductFilterBase title="Bestemming">
        {sailingAreas.map((sailingArea, index) => {
          return (
            <label key={index} className="checkbox">
              <input
                className="checkbox__input"
                type="checkbox"
                checked={this.isInSelectedSailingAreas(sailingArea)}
                onChange={this.handleSailingAreaClick.bind(this, sailingArea)}
              />
              <span className="checkbox__label">{sailingArea.name}</span>
            </label>
          );
        })}
      </ProductFilterBase>
    );
  }
}

export default ProductFilterSailingArea;
