import React from "react";

class ProductFilterBase extends React.Component {
  constructor(props) {
    super(props);

    this.handleCollapseClick = this.handleCollapseClick.bind(this);

    this.state = {
      isCollapsed: false,
    };
  }

  // Actions

  handleCollapseClick() {
    const { isCollapsed } = this.state;

    this.setState({
      isCollapsed: !isCollapsed,
    });
  }

  // Render

  render() {
    const { isCollapsed } = this.state;
    const { title, children } = this.props;

    return (
      <div className={isCollapsed ? "collapsable" : "collapsable collapsable--active"}>
        <div className="collapsable__header" onClick={this.handleCollapseClick}>
          <label className="collapsable__heading">{title}</label>
          {children && <button type="button" className="collapsable__toggle" title="Toon/verberg"></button>}
        </div>
        <div className="collapsable__body">
          <div className="flyin__filter-group">{children}</div>
        </div>
      </div>
    );
  }
}

export default ProductFilterBase;
