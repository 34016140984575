import { find, remove } from "lodash";

import ProductFilterBase from "./productFilterBase";
import React from "react";

class ProductFilterShippingCompany extends React.Component {
  // Actions

  handleShippingCompanyClick(shippingCompany) {
    const { selectedShippingCompanies, onSelectionChange } = this.props;

    if (find(selectedShippingCompanies, { itemId: shippingCompany.itemId })) {
      // Deselect shippingCompany
      remove(selectedShippingCompanies, selectedShippingCompany => {
        return selectedShippingCompany.itemId === shippingCompany.itemId;
      });
    } else {
      // Select shippingCompany
      selectedShippingCompanies.push(shippingCompany);
    }

    // Update
    if (onSelectionChange) {
      onSelectionChange(selectedShippingCompanies);
    }
  }

  // Utils

  isInSelectedShippingCompanies(shippingCompany) {
    const { selectedShippingCompanies } = this.props;
    return Boolean(find(selectedShippingCompanies, shippingCompany));
  }

  render() {
    const { shippingCompanies } = this.props;

    return (
      <ProductFilterBase title="Rederij">
        {shippingCompanies.map((shippingCompany, index) => {
          return (
            <label key={index} className="checkbox">
              <input
                className="checkbox__input"
                type="checkbox"
                checked={this.isInSelectedShippingCompanies(shippingCompany)}
                onChange={this.handleShippingCompanyClick.bind(this, shippingCompany)}
              />
              <span className="checkbox__label">{shippingCompany.name}</span>
            </label>
          );
        })}
      </ProductFilterBase>
    );
  }
}

export default ProductFilterShippingCompany;
