import React from "react";
import { add, differenceInMonths, isEqual } from "date-fns";
import { format } from "../../../utils/dateUtils";
import { remove, find } from "lodash";
import ProductFilterBase from "./productFilterBase";

class ProductFilterPeriod extends React.Component {
  dateFormat = "MMMM yyy";

  // Actions

  handleMonthClick(month) {
    const { selectedPeriod, onSelectionChange } = this.props;

    if (find(selectedPeriod, period => isEqual(month, period))) {
      // Deselect month
      remove(selectedPeriod, period => isEqual(month, period));
    } else {
      // Select month
      selectedPeriod.push(month);
    }

    // Update
    if (onSelectionChange) {
      onSelectionChange(selectedPeriod);
    }
  }

  // Utils

  getPeriod(periodMinDate, periodMaxDate) {
    const difference = differenceInMonths(periodMaxDate, periodMinDate);

    const period = [];
    for (let i = 0; i <= difference; i++) {
      period.push(add(periodMinDate, { months: i }));
    }
    return period;
  }

  getMonthTitle(month) {
    return format(month, this.dateFormat);
  }

  isInSelectedPeriod(month) {
    const { selectedPeriod } = this.props;
    return Boolean(
      find(selectedPeriod, selectedMonth => {
        return isEqual(month, selectedMonth);
      })
    );
  }

  // Lifecycle

  render() {
    const { periodMinDate, periodMaxDate } = this.props;

    return (
      <ProductFilterBase title="Periode">
        {this.getPeriod(periodMinDate, periodMaxDate).map((month, index) => {
          return (
            <label key={index} className="checkbox">
              <input
                className="checkbox__input"
                type="checkbox"
                checked={this.isInSelectedPeriod(month)}
                onChange={this.handleMonthClick.bind(this, month)}
              />
              <span className="checkbox__label">{this.getMonthTitle(month)}</span>
            </label>
          );
        })}
      </ProductFilterBase>
    );
  }
}

export default ProductFilterPeriod;
