import Actions from "./actions";
import Form from "../contact/contactForm";
import LoadingSpinner from "../loadingSpinner";
import ProductCard from "./productCard";
import React from "react";
import { environment } from "../../environments/environment";

export default ({ products, isLoading, onFiltersClick, onSortClick, theme, order, privacy, emailTemplate }) => {
  let filteredProducts = [];
  // dit is omdat de Form component ook gevoed wordt rechtstreeks van CMS ipv altijd uit GraphQL
  const data = {
    content: {
      general: {
        conformationEmailTemplate: { key: emailTemplate?.tideId ?? 12 },
        title: "Niet jouw droomreis gevonden?",
      },
    },
  };

  if (theme === "thalassa") {
    filteredProducts = products.filter(p => p.cms.content.general.isThalassa);
  }
  if (theme === "select-together") {
    filteredProducts = products.filter(p => p.cms.content.general.isSelectTogether);
  }
  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <Actions
          count={filteredProducts.length}
          order={order}
          onFiltersClick={onFiltersClick}
          onSortClick={onSortClick}
        />
      )}
      <section className="products">
        <div className="products__content">
          {isLoading ? (
            <LoadingSpinner text="Uw cruises worden geladen" />
          ) : filteredProducts.length > 0 ? (
            filteredProducts.map((product, index) => (
              <div className="promotions__item" key={index}>
                <ProductCard product={product} theme={theme} />
              </div>
            ))
          ) : (
            <Form privacy={privacy} to={environment.builds[0].mail} data={data} />
          )}
        </div>
      </section>
    </>
  );
};
