import { find, remove } from "lodash";

import ProductFilterBase from "./productFilterBase";
import React from "react";

class ProductFilterCruiseType extends React.Component {
  // Actions

  handleCruiseTypeClick(cruiseType) {
    const { selectedCruiseTypes, onSelectionChange } = this.props;

    if (find(selectedCruiseTypes, { itemId: cruiseType.itemId })) {
      // Deselect cruiseType
      remove(selectedCruiseTypes, selectedCruiseType => selectedCruiseType.itemId === cruiseType.itemId);
    } else {
      // Select cruiseType
      selectedCruiseTypes.push(cruiseType);
    }

    // Update
    if (onSelectionChange) {
      onSelectionChange(selectedCruiseTypes);
    }
  }

  // Utils

  isInSelectedCruiseTypes(cruiseType) {
    const { selectedCruiseTypes } = this.props;
    return Boolean(find(selectedCruiseTypes, cruiseType));
  }

  // Lifecycle

  render() {
    const { cruiseTypes } = this.props;

    return (
      <ProductFilterBase title="Type cruise">
        {Boolean(cruiseTypes && cruiseTypes.length) &&
          cruiseTypes.map((cruiseType, index) => {
            return (
              Boolean(cruiseType && cruiseType.content && cruiseType.content.general) && (
                <label key={index} className={this.isInSelectedCruiseTypes ? "checkbox checkbox--active" : "checkbox"}>
                  <input
                    className="checkbox__input"
                    type="checkbox"
                    checked={this.isInSelectedCruiseTypes(cruiseType)}
                    onChange={this.handleCruiseTypeClick.bind(this, cruiseType)}
                  />
                  <span className="checkbox__label">{cruiseType.content.general.title}</span>
                </label>
              )
            );
          })}
      </ProductFilterBase>
    );
  }
}

export default ProductFilterCruiseType;
